@import '~rsuite/lib/styles/themes/dark/index.less';

html {
  scroll-behavior: smooth;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 10px;
  cursor: pointer;
}

@font-face {
  font-family: VagRounded;
  src: url('./fonts/VagRounded.otf') format("opentype");
}

.logo-text {
  font-family: VagRounded;
  color: white;
}

.brand {
  color: @base-color
}

.user-dropdown .rs-dropdown-toggle {
  display: flex!important;
  justify-content: center!important;
  align-content: center!important;
  padding: 7px 32px 7px 7px!important;
  align-items: center;
}


.answers__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  grid-gap: 5px;
  align-items: center;
  .rs-btn-block + .rs-btn-block {
    margin: 0!important;
  }

}

@media only screen and (max-width: 480px) {
  .logo-container {
    width: 130px;
  }

  .logo-text {

  }

  .rs-avatar {
    width: 30px;
    height: 30px;
  }

}
@base-color: #FF640C;